<template>
  <div>
    <a-modal
      class="auth-modal default-modal"
      :visible="visible"
      title="Вход"
      :footer="null"
      :closable="false"
      :width="438"
      centered
      @cancel="closeModal"
    >
      <a-form ref="ruleForm" :model="form">
        <a-form-item name="cert" class="auth-modal__cert" :rules="[{ required: true, message: 'Выберите сертификат' }]">
          <span class="input-label">Выберите сертификат </span>
          <a-upload
              class="auth-modal__upload"
              :multiple="false"
              :before-upload="beforeUpload"
              :show-upload-list="false"
              type="file"
              :capture="null"
          >
              <a-button class="default-button">
                  Выбрать сертификат
              </a-button>
          </a-upload>
          <div class="auth-modal__file" v-if="file">
            <span class="auth-modal__file-name">{{ file.name }}</span>
            <delete-outlined @click="deleteFile" />
          </div>
        </a-form-item>
        <a-form-item name="password" :rules="[{ required: true, message: 'Пожалуйста введите пароль!' }]">
          <span class="input-label">Введите Пароль от сертификата</span>
          <a-input-password
            class="input-password"
            :value="form.password" 
            @input="form.password = $event.target.value"
            autocomplete="new-password"
            placeholder="Введите пароль сертификата"
          />
        </a-form-item>
        <a-form-item>
          <a-button class="auth-modal__button default-button" html-type="submit" :loading="isLoading" @click="onSubmit">
            Войти
          </a-button>
        </a-form-item>
        <a-form-item v-if="false" class="auth-modal__register">
                <span  @click="(registerVisible = true), closeModal()">Регистрация</span>
        </a-form-item>
      </a-form>
    </a-modal>
    <Register :visible="registerVisible" @closeModal="closeRegister" />
  </div>
</template>
<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import { JSEncrypt } from 'jsencrypt';

import Register from "@/components/modals/Register.vue";
import { getErrorText } from "@/static/getErrorText";
export default {
  name: "AuthVue",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Register,
    DeleteOutlined
  },
  data() {
    return {
      form: {
        cert: "",
        password: "",
      },
      file: null,
      registerVisible: false,
      base64: null,
      isLoading: false,
      encryptor: null
    };
  },
  watch: {
    file(newVal) {
      if (newVal) {
        this.convertToBase64(newVal);
      } else {
        this.base64 = null;
      }
    },
  },
  methods: {
    fieldValidate() {
      this.$refs.ruleForm.validateFields().then(() => {
        this.login();
        return true;
      }).catch(() => {
        return false;
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    closeModal() {
      this.resetForm();
      this.base64 = null;
      this.file = null
      this.$emit("closeModal", false);
    },
    onSubmit() {
      this.fieldValidate();
    },
    deleteFile() {
      this.file = null;
      this.form.cert = null;
    },
    closeRegister(value) {
      this.registerVisible = value;
    },
    convertToBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
      };
    },
    login() {
      this.setupEncryptor();
      let data = {
        password: this.encryptor.encrypt(this.form.password),
      };

      if (this.file) {
        data.cert = this.base64;
      }

      this.isLoading = true;

      this.$store.dispatch("auth/login", data).then(
        () => {
          this.closeModal();
          this.$router.push("/signing");
        },
        (error) => {
          if (error.response.data) {
            this.errorsHandling(error.response.data.message);
          } else {
            this.errorsHandling('tech_error');
          }
        }
      ).finally(() => {
        this.isLoading = false;
      });
    },
    errorsHandling(message) {
      this.$message.error(getErrorText(message));
    },
    beforeUpload(file) {
      if (file.type === 'application/x-pkcs12') {
        this.file = file;
        this.form.cert = file.name;
      } else {
        this.$message.error("Файл не соответствует формату p12");
      }
      return false;
    },
    setupEncryptor() {
      const publicKey = process.env.VUE_APP_PUBLIC_KEY;
      this.encryptor = new JSEncrypt();
      this.encryptor.setPublicKey(publicKey);
    },
  },
};
</script>
<style lang="scss">
.auth-modal {
  &_hidden {
    display: none !important;
  }

  &__button {
    width: 100%;
  }

  &__file {
    padding: 10px 0 0;
    color: #a0aac2;
    display: flex;

    i {
      line-height: 24px;
      color: #f00;
    }

    
  }

  &__file-name {
    width: 90%;
    overflow: hidden;
    margin-right: 10px;

    display: flex;
    line-height: 1;
    align-items: center;
  }

  &__upload {
    .ant-upload {
        width: 100%;
        button {
          width: 100%;
        }
    }
  }
  
  &__cert {
    .has-error .ant-form-explain {
        font-size: 14px;
      }
  }

  .ant-form-item {
    margin-bottom: 18px;
  }

  &__register {
        .ant-form-item-control {
            line-height: 1;
        }

        span {
            cursor: pointer;
            display: flex;
            justify-content: center;
            color: #A0AAC2;
            font-size: 14px;
            font-weight: 500;
        }

        @media screen and (min-width: 768px) {
            span {
                font-size: 16px;
            }
        }
    }
}
</style>
