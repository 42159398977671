import axiosInstance from "../services/api";
import TokenService from "./token.service";
import {decodeJWT} from "./tokenUtils";
class AuthService {
  login({ cert, password }) {
    return axiosInstance
      .post("https://signature.kz/api/auth/login", {
        cert,
        password
      })
      .then((response) => {
        if (response.data.access_token) {
          const data = response.data;
          data.ceo = decodeJWT(response.data.access_token).ceo;
          TokenService.setUser(data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register({ certificate_data, email, certificate_password }) {
    return axiosInstance.post("https://signature.kz/api/auth/sign-up", {
      certificate_data,
      email,
      certificate_password
    })
    .then((response) => {
      if (response.data.access_token) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
  }
}

export default new AuthService();
