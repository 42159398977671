import { createApp } from 'vue'; // Импортируем createApp из Vue 3 вместо Vue
import App from './App.vue';
import router from './router';
import store from './store';
import ApiPlugin from './plugins/api';
import VueInputMask from './plugins/vue-inputmask';
import { Button, Collapse, Input, message, Modal, Checkbox, Row, Col, Radio, Drawer, Select, Upload, Card, InputNumber, Form, Skeleton } from 'ant-design-vue';
import './assets/scss/main.scss';
import './interceptors/interceptors';

const app = createApp(App); // Создаем экземпляр приложения с помощью createApp

app.use(Button);
app.use(Modal);
app.use(Input);
app.use(Collapse);
app.use(Form);
app.use(ApiPlugin);
app.use(Checkbox);
app.use(Row);
app.use(Col);
app.use(Radio);
app.use(Drawer);
app.use(Select);
app.use(Upload);
app.use(Card);
app.use(InputNumber);
app.use(VueInputMask);
app.use(Skeleton);

app.config.productionTip = false;
app.config.globalProperties.$message = message; // Используем globalProperties для добавления свойств к экземпляру Vue

app.use(store);
app.use(router);

app.mount('#app');
