<template>
	<div id="app">
		<TheHeader>
			<router-view />
		</TheHeader>
		<main>
			<router-view />
		</main>
		<TheFooter />
		
		<BottomNavigation v-if="false"/>
	</div>
</template>

<script>
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import BottomNavigation from './components/BottomNavigation.vue';
export default {
	name: 'App',
	components: {
		TheHeader,
		TheFooter,
		BottomNavigation
	},
	created() {
        this.$store.dispatch('auth/autoLogin');
    },
	computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
}
</script>
<style lang="scss">
#app {
	display: flex;
    height: 100%;
    flex-direction: column;
    min-height: 0;
}
</style>