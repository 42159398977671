import axios from 'axios'
// import axiosInstance from "../services/api";
import TokenService from "../services/token.service";
import store from '../store';
import router from '../router';

axios.interceptors.request.use(
	(config) => {
		const token = TokenService.getLocalAccessToken();
		if (token) {
			config.headers["Authorization"] = 'Bearer ' + token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(resp => resp, async error => {
	if (error.response.status === 401 && error.response.data.message === 'token_expired') {
		store.dispatch('auth/logout');
		router.push('/');
	}
	return Promise.reject(error);
});