import AuthService from '../services/auth.service';
import { addHours, differenceInSeconds, parseISO } from 'date-fns';
import router from '@/router';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
	? { status: { loggedIn: true }, user }
	: { status: { loggedIn: false }, user: null };
let timer = '';
export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		login({ commit, dispatch }, user) {
			return AuthService.login(user).then(
				user => {
					const result = addHours(new Date(), 6);
					timer = setTimeout(() => {
						dispatch('autoLogout');
					}, differenceInSeconds(result, new Date()) * 1000);
					localStorage.setItem('expiration', JSON.stringify(result));
					commit('loginSuccess', user);
					return Promise.resolve(user);
				},
				error => {
					commit('loginFailure');
					return Promise.reject(error);
				}
			);
		},
		logout({ commit}) {
			AuthService.logout();
			localStorage.removeItem('expiration');
			commit('logout');
			if (timer) {
				clearTimeout(timer);
			}
			if (router.currentRoute.path !== '/') {
				router.push('/');
			}
			
		},
		autoLogout(context) {
			context.dispatch('logout');
			context.commit('autoLogout');
		},
		register({ commit, dispatch }, user) {
			return AuthService.register(user).then(
				response => {
					const result = addHours(new Date(), 6);
					timer = setTimeout(() => {
						dispatch('autoLogout');
					}, differenceInSeconds(result, new Date()) * 1000);
					localStorage.setItem('expiration', JSON.stringify(result));
					commit('loginSuccess', response);
					return Promise.resolve(response);
				},
				error => {
					commit('loginFailure');
					return Promise.reject(error);
				}
			);
		},
		refreshToken({ commit }, data) {
			commit('refreshToken', data);
		},
		autoLogin({ dispatch }) {
			let expiration = JSON.parse(localStorage.getItem('expiration'));
			let userData = JSON.parse(localStorage.getItem('user'));
			let diff = differenceInSeconds(parseISO(expiration), new Date());
			if (userData) {
				if (diff < 1800) {
					dispatch('autoLogout');
				} else {
					timer = setTimeout(() => {
						dispatch('autoLogout');
					}, diff * 1000);
				}
			}
		},
	},
	mutations: {
		loginSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		loginFailure(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		logout(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		registerSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		registerFailure(state) {
			state.status.loggedIn = false;
		},
		refreshToken(state, data) {
			state.status.loggedIn = true;
			state.user = { ...state.user, access_token: data.access_token, refresh_token: data.refresh_token };
		},
		autoLogout(state) {
			state.autoLogout = true;
		}
	}
};
