<template>
    <a-modal class="setting-modal default-modal" :visible="visible" title="Настройки" :footer="null" :closable="false" :width="438" @cancel="closeModal">
        <a-form
            ref="ruleForm"
            :model="form"
        >
            <a-form-item name="email" class="input">
                <span class="input-label">Новый e-mail</span>
                <a-input v-model="form.email" placeholder="Введите e-mail" />
            </a-form-item>
            <a-form-item name="oldEmail" class="input">
                <span class="input-label">Введите старый e-mail</span>
                <a-input v-model="form.oldEmail" placeholder="Введите старый e-mail" />
            </a-form-item>
            <a-form-item>
                <a-button class="setting-modal__button default-button">
                    Подтвердить e-mail
                </a-button>
            </a-form-item>
            <a-form-item>
                <a-button class="setting-modal__button default-button" @click="onSubmit">
                    Сохранить
                </a-button>
            </a-form-item>
            <a-form-item class="setting-modal__cancel">
                <span @click="closeModal">Отменить</span>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
export default {
    name: 'SettingVue',
    props: {
        visible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            form: {
                email: '',
                oldEmail: '',
            },
        }
    },
    methods: {
        fieldValidate() {
            this.$refs.ruleForm.validateFields().then(() => {
                return true;
            }).catch(() => {
                return false;
            });
        },
        closeModal() {
            this.$emit('closeModal', false)
        },
        onSubmit() {
            this.fieldValidate();
        },
    }
}
</script>
<style lang="scss">
.setting-modal {
    &__button {
        width: 100%;
        min-height: 46px;
    }

    &__cancel {
        .ant-form-item-control {
            line-height: 1;
        }

        span {
            cursor: pointer;
            display: flex;
            justify-content: center;
            color: #A0AAC2;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .ant-form-item {
        margin-bottom: 18px;
    }
}
</style>