<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__wrapper">
                <img src="@/assets/images/ihub.svg" alt="signature" />
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'TheFooter'
}
</script>

<style lang="scss" scoped>
.footer {
    padding-bottom: 100px;
    padding-top: 50px;
    background: linear-gradient(180deg, rgba(225, 228, 238, 0.00) 0%, #E1E4EE 48.34%);
    
    &__wrapper {
        display: flex;
        justify-content: center;
    }
}
</style>