<template>
    <div class="bottom-navigation">
        <RouterLink :to="{ name: 'home'}" :class="['bottom-navigation__item', currentRoute === 'home' ? 'bottom-navigation__item_active' : '' ]" >
            <img v-if="currentRoute === 'home'" src="@/assets/images/home-wifi-active.svg" alt="home-wifi">
            <img v-else src="@/assets/images/home-wifi.svg" alt="home-wifi">
            Главная
        </RouterLink>
        <RouterLink :to="{ name: 'signing'}" :class="['bottom-navigation__item', currentRoute === 'signing' ? 'bottom-navigation__item_active' : '' ]" >
            <div>
                <img v-if="currentRoute === 'signing'" src="@/assets/images/edit-active.svg" alt="edit">
                <img v-else src="@/assets/images/edit.svg" alt="edit">
            </div>
            Подписать
        </RouterLink>
        <RouterLink :to="{ name: 'create'}" :class="['bottom-navigation__item', currentRoute === 'create' ? 'bottom-navigation__item_active' : '' ]">
            <img v-if="currentRoute === 'create'" src="@/assets/images/document-active.svg" alt="edit">
            <img v-else src="@/assets/images/document.svg" alt="edit">
            <span>Создать</span>
        </RouterLink>
        <RouterLink :to="{ name: 'history'}" :class="['bottom-navigation__item', currentRoute === 'history' ? 'bottom-navigation__item_active' : '' ]">
            <img v-if="currentRoute === 'history'" src="@/assets/images/check-active.svg" alt="edit">
            <img v-else src="@/assets/images/check-icon.svg" alt="edit">
            <span>История</span>
        </RouterLink>
    </div>
  </template>
  
  <script>
  export default {
    data() {
        return {
            currentRoute: null,
        }
    },
    created() {
        this.currentRoute = this.$route.path.substring(1) ? this.$route.path.substring(1) : 'home';
    },
    watch:{
        '$route' (to){
            this.currentRoute = to.path.substring(1) ? to.path.substring(1) : 'home';
        }
    },
  };
  </script>
  
<style lang="scss" scoped>
.bottom-navigation {
    display: none;

    @media only screen and (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;

        img {
            width: 27px;
            height: 27px;
            margin-bottom: 5px;
        }

        &__item {
            color: #A0AAC2;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &__item_active {
            color: #1C1C20;
        }
    }
}
  
.nav-item {
    cursor: pointer;
}
</style>
  