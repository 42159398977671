import Inputmask from 'inputmask';

const VueInputMask = {
  install: function(app) {
    app.directive('mask', {
      mounted(el, binding) {
        Inputmask(binding.value).mask(el);
      }
    });
  }
};

export default VueInputMask;
