<template>
    <a-modal class="register-modal default-modal" :visible="visible" title="Регистрация" :footer="null" :closable="false" :width="438" centered @cancel="closeModal">
        <a-form
            ref="ruleForm"
            :model="form"
        >
            <a-form-item name="cert" class="register-modal__cert" :rules="[{ required: true, message: 'Выберите сертификат' }]">
                <span class="input-label">Выберите сертификат</span>
                <a-upload
                    class="register-modal__upload"
                    :multiple="false"
                    :before-upload="beforeUpload"
                    :show-upload-list="false"
                >
                    <a-button class="default-button">
                        Выбрать сертификат
                    </a-button>
                </a-upload>
                <div class="register-modal__file" v-if="file"><span>{{ file.name }}</span><delete-outlined @click="deleteFile" /></div>
            </a-form-item>
            <a-form-item v-if="false">
                <checkbox class="register-modal__checkbox" label="Сохранить сертификат" v-model="saveCert" :checked="saveCert" id="saveCert" />
            </a-form-item>
            <a-form-item v-if="saveCert" name="email" class="input">
                <span class="input-label">Введите Email</span>
                <a-input v-model="form.email" placeholder="Введите Email" />
            </a-form-item>
            <a-form-item name="password" :rules="[{ required: true, message: 'Пожалуйста введите пароль!' }]">
                <span class="input-label">Введите Пароль от сертификата</span>
                <a-input-password class="input-password"
                    :value="form.password" 
                    @input="form.password = $event.target.value"
                    autocomplete="new-password" 
                    placeholder="Введите пароль сертификата" />
            </a-form-item>
            <a-form-item>
                <a-button class="register-modal__button default-button" @click="onSubmit">
                    Зарегистрироваться
                </a-button>
            </a-form-item>
            <a-form-item class="register-modal__cancel">
                <span @click="closeModal">Отменить</span>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import Checkbox from '@/components/Checkbox.vue';
export default {
    name: 'RegisterVue',
    props: {
        visible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Checkbox,
        DeleteOutlined
    },
    data() {
        return {
            form: {
                cert: '',
                password: '',
                email: '',
            },
            file: null,
            saveCert: false,
            message: null,
            base64: null
        }
    },
    watch: {
		file(newVal) {
			if (newVal) {
				this.convertToBase64(newVal);
			} else {
				this.base64 = null;
			}
		},
	},
    methods: {
        fieldValidate() {
            this.$refs.ruleForm.validateFields().then(() => {
                this.signUp();
                return true;
            }).catch(() => {
                return false;
            });
        },
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },
        closeModal() {
            this.resetForm();
            this.base64 = null;
            this.$emit('closeModal', false)
        },
        onSubmit() {
            this.fieldValidate();
        },
        deleteFile() {
            this.file = null;
            this.form.cert = null;
        },
        convertToBase64(file) {
			const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					this.base64 = reader.result.replace('data:', '').replace(/^.+,/, '');
				};
		},
        signUp() {
            const data = {
                certificate_data: this.base64,
                login: this.form.email ? this.form.email : null,
                certificate_password: this.form.password
            }
            this.$store.dispatch('auth/register', data).then(
            () => {
                this.closeModal();
                this.$router.push('/signing');
            },
            error => {
              if(error.response) {
                this.errorsHandling(error.response.data.responses)
              }
            }
          );
        },
        errorsHandling(message) {
			switch (message) {
				case "certificate password incorrect":
					this.$message.error('Неверный пароль');
					break;
				case 'wrong certificate type':
					this.$message.error('Выберите сертификат для входа');
					break;
                case 'user with this iin and bin already exist':
                    this.$message.error('Пользователь с таким сертификатом уже зарегистрирован');
                    break;
                case 'certificate has expired':
					this.$message.error('Срок действия сертификата истек');
					break;
				default:
					break;
			}
		},
        beforeUpload(file) {
            if (file.type === 'application/x-pkcs12') {
                this.file = file;
                this.form.cert = file.name;
            } else {
                this.$message.error("Файл не соответствует формату p12");
            }
            return false;
        },
    }
}
</script>
<style lang="scss">
.register-modal {
    &_hidden {
        display: none !important;
    }

    &__button {
        width: 100%;
        min-height: 46px;
    }

    &__file {
        padding: 10px 0 0;
        color: #A0AAC2;
        display: flex;

        i {
            line-height: 24px;
            color: #F00;
        }

        span {
            width: 90%;
            overflow: hidden;
            margin-right: 10px;

            display: flex;
            line-height: 1;
            align-items: center;
        }
    }

    &__cancel {
        .ant-form-item-control {
            line-height: 1;
        }

        span {
            cursor: pointer;
            display: flex;
            justify-content: center;
            color: #A0AAC2;
            font-size: 14px;
            font-weight: 500;
        }

        @media screen and (min-width: 768px) {
            span {
                font-size: 16px;
            }
        }
    }

    &__upload {
        .ant-upload {
            width: 100%;
            button {
            width: 100%;
            }
        }
    }

    &__cert {
        .has-error .ant-form-explain {
            font-size: 14px;
        }
    }

    &__checkbox {
        .custom-checkbox__label {
            color: #A0AAC2 !important;
        }
    }

    .ant-form input[type=checkbox]:focus  {
        outline: none;
    }
    
    .ant-form-item {
        margin-bottom: 18px;
    }
}
</style>