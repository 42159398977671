import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/signing',
    name: 'signing',
    component: () => import('../views/Signing.vue'),
  },
  {
    path: '/signing/:id',
    component: () => import('../views/Signing.vue'),
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/History.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/create',
    name: 'create',
    component: () => import('../views/Create.vue'),
  },
  // {
  //   path: '/certificates',
  //   name: 'certificates',
  //   component: () => import('../views/Certificates.vue'),
  // },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/Agreement.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return {
      top: 0,
      left: 0,
      behavior: 'smooth',
    };
  },
});

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const requiresAuth = to.meta.requiresAuth;

  if (requiresAuth && !user) {
    next('/');
  } else if (requiresAuth && user) {
    next();
  } else {
    next();
  }
});

export default router;