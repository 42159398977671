<template>
    <div class="custom-checkbox">
      <input type="checkbox" :name="name" :class="className" :id="id" :value="value" :checked="checked" :required="required" @change="updateInput" @click="onClick">
      <label class="custom-checkbox__label custom-checkbox__label_gray" :for="id">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'CustomCheckbox',
    props: {
        name: {
            type: String,
            required: false
        },
        className: {
            type: String,
            required: false
        },
        id: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        checked: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: Boolean,
            required: false
        },
    },
    methods: {
        updateInput(event) {
            this.$emit('input', event.target.checked);
        },
        onClick(e) {
			this.$emit('onClick', e);
		},
    }
}
</script>